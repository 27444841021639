.root {
	width: 100%;
	height: 100%;
	padding: 15px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.presentation {
	margin: 0 10px;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 600px;
}

.presentation h1 {
	text-align: center;
	margin-bottom: 15px;
}

.presentationButtons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.presentationButtons > a,
.presentationButtons > button {
	margin-top: 15px;
}

.demo {
	margin-top: 50px;
	width: 100%;
	max-width: calc(100% - (10px * 2));
	display: flex;
	flex-direction: column;
	align-content: center;
}
