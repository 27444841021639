body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	background-color: #262626;
	color: white;
}

.page {
	height: calc(100vh - 64px);
	max-height: calc(100vh - 64px);
	display: flex;
}

@media only screen and (max-width: 599px) {
	.page {
		height: calc(100vh - 56px);
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
	border: rgba(255, 255, 255, 0.226) 1px solid;
	background-color: rgba(0, 0, 0, 0.192);
	padding: 0 2px;
	border-radius: 2px;
}

blockquote {
	margin: 24px 0;
	padding: 24px;
	border-left: 5px solid #ff5502;
	background-color: rgba(228, 139, 45, 0.2);
}

.paypal-checkout-sandbox-iframe {
	border: 0;
}

/* Works on Firefox */
* {
	scrollbar-color: #424242 #262626;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #262626;
}

*::-webkit-scrollbar-thumb {
	background-color: #424242;
	border-radius: 20px;
	border: 3px solid #262626;
}

* {
	-webkit-tap-highlight-color: transparent;
}
