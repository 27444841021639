.root {
	width: 100%;
	background-color: #1d1d1d;
	color: rgba(255, 255, 255, 0.61);
}

.linkSection {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	padding-bottom: 20px;
}

.linkSection > div {
	display: flex;
	flex-direction: column;
	margin: 0 15px;
	min-width: 175px;
}

.linkSection a {
	color: inherit;
	line-height: 25px;
}

.denseFooter {
	text-align: center;
	padding: 10px;
	background-color: #161616;
}
