.toolbar {
	padding-left: 0 !important;
}

.titleWrapper {
	display: flex;
}

.title {
	flex-grow: 1;
	padding-left: 24px;
}

.title a {
	text-decoration: none;
	color: inherit;
}
.title a:visited {
	color: inherit;
}


.logo {
	width: 73px;
	height: 64px;
	min-width: 64px;
	background-size: cover;
	align-self: flex-end;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 64px, 73px;
}

.menuButton {
	display: none !important;
}

.list {
	width: 250px;
}

@media only screen and (max-width: 599px) {
	.menuButton {
		display: initial !important;
	}

	.navEntries {
		display: none !important;
	}
}
