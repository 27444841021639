.root {
	position: fixed;
	bottom: 15px;
	left: 15px;
	max-width: 520px;
	z-index: 10000;
}

.actions {
	justify-content: flex-end;
}
