.root {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	text-align: center;
}
.root h1 {
	font-size: 135px;
	margin: 0;
}

.root h2 {
	font-size: 34px;
	margin-top: 0;
}
