.main {
	width: 72px;
	min-width: 72px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: auto;
	overflow-x: hidden;
}

.mainRightBorder {
	border-right: rgba(255, 255, 255, 0.096) 1px solid;
}

.dividerWrapper {
	min-width: 72px;
}

.divider {
	border-bottom: 1px solid rgba(255, 255, 255, 0.219);
	width: 48px;
	margin: 8px auto 0;
}

.itemWrapper {
	position: relative;
	height: 48;
	width: 72px;
	margin: 8px 0 0;
	display: flex;
}

.itemWrapper:last-child {
	margin: 8px 0;
}
.item {
	cursor: pointer;
	width: 48px;
	height: 48px;
	margin: auto;
	border-radius: 50px;
	transition: 200ms;
	display: flex;
	overflow: hidden;
}

.item:hover,
.item:focus-within {
	border-radius: 15px;
}

.itemImage {
	height: 100%;
	width: 100%;
	background-size: contain;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	transition: all linear 120ms;
	background-color: #424242;
	color: white;
}

.itemImage:hover,
.itemImage:focus,
.active {
	background-color: #f28410;
	color: black;
	outline: none;
}

.itemImage.disableDefaultTint:hover,
.itemImage.disableDefaultTint:focus-within,
.disableDefaultTint.active {
	background-color: #424242;
	color: white;
}

.selectedItemIndicator {
	position: absolute;
	top: 4px;
	height: 40px;
	width: 4px;
	background-color: white;
	transition: linear 220ms;
	border-radius: 0 12px 12px 0;
}

/* Works on Firefox */
.main {
	scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
.main::-webkit-scrollbar {
	width: 9px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollBar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScrollBar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
