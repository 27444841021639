.root {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.root h1 {
	text-align: center;
}

.root h2:first-of-type {
	margin: 0 0 16px 0;
}

.root p,
.root li {
	line-height: 29px;
	color: rgb(223, 223, 223);
}

.root .space {
	padding-bottom: 20px;
}
