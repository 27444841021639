.root {
	place-self: center;
	width: 100%;
	max-width: 1000px;
}

.root h1 {
	text-align: center;
}

.invalidWidth {
	height: 550px;
	padding: 20px;
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: black;
	text-align: center;
}

.invalidWidth svg {
	font-size: 5rem;
	margin-bottom: 20px;
}

.demoWrapper {
	box-shadow: black 0px 0px 10px -2px;
	border-radius: 5px 5px 0 0;
	overflow: hidden;
}

.demo {
	height: 550px;
	background-color: #202225;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	user-select: none;
	overflow: hidden;
}

@media only screen and (max-width: 599px) {
	.invalidWidth {
		display: flex !important;
	}

	.demo {
		display: none !important;
	}
}

.serverChannelList {
	width: 240px;
	background-color: #2f3136;
}

.serverChannelListBar {
	box-shadow: 0 1px 0 rgba(4, 4, 5, 0.2), 0 1.5px 0 rgba(6, 6, 7, 0.05),
		0 2px 0 rgba(4, 4, 5, 0.05);
	height: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 16px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 15px;
	margin-bottom: 8px;
}

.serverChannelList > .item {
	margin: 2px 8px;
	height: 34px;
	border-radius: 4px;
	cursor: pointer;
	color: rgb(204, 204, 204);
	display: flex;
	align-items: center;
	box-sizing: content-box;
}

.outlined {
	border: 3px dashed #f28410;
	border-radius: 4px;
}

.serverChannelList > .item p {
	height: 100%;
	padding-left: 4px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.serverChannelList > .item.fixTextType p span {
	position: relative;
	top: -2px;
}

.serverChannelList > .item:hover {
	background-color: rgba(255, 255, 255, 0.062);
}

.serverChannelList > .item.active {
	background-color: rgba(255, 255, 255, 0.096);
	color: rgb(236, 236, 236);
}

.serverChannelList > .item.disabled {
	cursor: not-allowed;
}

.serverChannelList > .item svg {
	color: rgb(139, 139, 139);
	position: relative;
	top: 5px;
}
.serverChannelList > .item .skeleton {
	height: 20px;
	margin-left: 8px;
	border-radius: 100px;
}

.serverChannelList > .item.category {
	margin-top: 12px;
	height: 18px;
	cursor: default;
}

.serverChannelList > .item.category > p {
	height: 15px;
	font-size: 0.8rem;
	position: relative;
	top: 0;
	left: -16px;
}

.serverChannelList > .item.category > p > span {
	height: 15px;
	position: relative;
	top: -2px;
}

.serverChannelList > .item.category > p > svg {
	font-size: 0.9rem;
	position: relative;
	top: 1px;
	left: 4px;
}

.serverChannelList > .item.category:hover {
	background-color: initial;
	color: rgb(236, 236, 236);
}

.serverChannelListBar > p {
	font-size: 16px;
	font-weight: bold;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.serverChannel {
	background-color: #36393f;
	flex-grow: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.serverChannelBar {
	box-shadow: 0 1px 0 rgba(4, 4, 5, 0.2), 0 1.5px 0 rgba(6, 6, 7, 0.05),
		0 2px 0 rgba(4, 4, 5, 0.05);
	height: 48px;
	padding: 0 0 0 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.serverChannelBar > p {
	font-size: 16px;
	font-weight: bold;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.serverChannelBar .separator {
	margin: 0 14px;
	height: 25px;
	border-left: 1px solid rgba(128, 128, 128, 0.336);
}

.serverChannelBar > svg {
	color: rgb(139, 139, 139);
}
.serverChannelBar > .topic {
	color: rgb(180, 180, 180);
	font-size: 0.9rem;
}

.serverChannelList > .item.outlined {
	height: 28px;
}

.serverChannelList > .item.outlined p {
	position: relative;
	top: -3px;
	left: -3px;
}

.serverChannelList > .item.category.outlined {
	height: 12px;
}

.serverChannelList > .item.category.outlined > p {
	position: relative;
	top: 0;
	left: -19px;
}

.serverChannelBar .topic.outlined {
	position: relative;
	left: -3px;
}

.serverChannelBar > .name {
	margin-left: 5px;
}

.serverChannelBar .grow {
	flex-grow: 1;
}

.serverChatWrapper {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.serverChat {
	width: 300px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
}

.serverChat a {
	margin-top: 7px;
}
